const uma_apis_url = process.env.NEXT_ISSERVER
  ? process.env.NEXT_SERVER_MEGALITH_URL
  : process.env.NEXT_PUBLIC_UMA_APIS_BASE_URL;

/**
 * Base URL for the Cemex API, configured via environment variable, NEXT_ISSERVER da true en el server y undefined en client, esto nos permite que nunca falle a donde tiene que ir el endpoint.
 */
export const alertAIApiUrl =
  process.env.NEXT_ISSERVER && process.env.NEXT_SERVER_ALERTAI_URL
    ? process.env.NEXT_SERVER_ALERTAI_URL
    : process.env.NEXT_PUBLIC_ALERTAI_URL;

const NLP = `${uma_apis_url}/ai`;

export const NODE_SERVER = process.env.NEXT_PUBLIC_NODE_URL;
export const MEGALITH = `${uma_apis_url}/doctor`;

export const stream_selfie =
  "https://stream-dot-uma-v2.appspot.com/stream_selfie";
export const callScreenshot = `${uma_apis_url}/media/images/uploadScreenshot`;
export const isFarmatodo = Boolean(process.env.NEXT_PUBLIC_ISFARMATODO);

/* Alert AI */
export const waiting_appointments = `${alertAIApiUrl}/operation/waiting-for-a-doctor`;
export const operation_url = `${alertAIApiUrl}/operation`;
export const getAlertAIOperationById = (id: number) => `${operation_url}/${id}`;
export const get_resume_responses = (step: string) =>
  `${alertAIApiUrl}/operation/${step}`;
export const take_operation = (operationId: number) =>
  `${alertAIApiUrl}/operation/${operationId}/take-apt-review`;

export const close_operation = (operationId: number) =>
  `${process.env.NEXT_PUBLIC_ALERTAI_URL}/operation/close/${operationId}`;

/* NODE SERVER */
export const delete_label = `${MEGALITH}/medicalrecords/deleteLabel`
export const close_mr_presence= `${NODE_SERVER}/medicalrecords/`
export const chat = `${MEGALITH}/chat/save`
export const chatAtt = `${MEGALITH}/chat/saveChatAtt`
export const chatAtt_viewMessage = `${MEGALITH}/chat/viewMessage`
export const close_async_session = `${MEGALITH}/chatAtt/closeSession`
export const stt = `${NODE_SERVER}/transcription/stt`
export const save_history = `${MEGALITH}/patient/history`
export const create_history = `${MEGALITH}/patient/history`
export const save_labels = `${MEGALITH}/medicalrecords/addLabel`
export const cancel_specialist_appointment = `${NODE_SERVER}/appointments/cancelSpecialistAppointment`
export const cancel_specialist_appointment_v2 = `${NODE_SERVER}/appointments/cancelSpecialistAppointmentV2`
export const cancel_specialist_appointment_batch = `${NODE_SERVER}/appointments/cancelSpecialistAppointmentBatch`
export const cancel_specialist_appointment_batch_v2 = `${NODE_SERVER}/appointments/cancelSpecialistAppointmentBatchV2`
export const generate_holiday = `${NODE_SERVER}/appointments/holidays/`
export const get_patient_by_dni = `${MEGALITH}/patient`
export const email_exists = `${MEGALITH}/provider/userExistsByEmail`
export const get_dni_cuit = `${MEGALITH}/providers/getDniCuit`
export const provider_exists = `${MEGALITH}/providers/exists`
export const providers = `${MEGALITH}/providers`
export const requestValidation = `${MEGALITH}/providers/requestValidation`
export const normalizeCorporate = `${NODE_SERVER}/patients/normalizeCorporate`
export const farmalink = `${MEGALITH}/prescriptions/farmalink`
export const audibaires = `${MEGALITH}/prescriptions/audibaires`
export const quick_farmalink = `${NODE_SERVER}/farmalink`
export const delete_farmalink = `${MEGALITH}/prescriptions/farmalink`
export const up_receta = `${MEGALITH}/prescriptions/unionPersonal`
export const up_receta_quick = `${NODE_SERVER}/unionpersonal/createQuickPrescription`
export const osde_receta = `${MEGALITH}/prescriptions/create/osde`
export const osde_receta_quick = `${MEGALITH}/prescriptions/quick/create/osde`
export const preserfar_receta = `${MEGALITH}/prescriptions/create/preserfar`
export const preserfar_receta_remove = `${MEGALITH}/prescriptions/cancel/preserfar`
export const preserfar_receta_quick = `${MEGALITH}/prescriptions/quick/create/preserfar`
export const save_recipe_db = `${NODE_SERVER}/prescriptions`
export const delete_recipe = `${NODE_SERVER}/prescriptions`
export const download_recipe = `${MEGALITH}/pdf/prescription`
export const download_prm = `${NODE_SERVER}/pdf/prm`
export const send_quick_prescription_mail = `${MEGALITH}/quickPrescription/share`
export const send_quick_prescription_ws = `${NODE_SERVER}/messaging/whatsapp/SendQuickPrescripion`
export const studies = `${NODE_SERVER}/prescriptions/order`
export const studies_pdf = `${MEGALITH}/pdf/order`
export const save_new_patient = `${MEGALITH}/provider/myPatients`
export const delete_patient = `${MEGALITH}/provider/myPatients`
export const doctorCreateAppointment = `${NODE_SERVER}/appointments/doctorCreatesAppointment`
export const confirmPendingAppoints = `${NODE_SERVER}/appointments/confirmPendingAppointment`
export const start_att_guard = `${MEGALITH}/appointments/startAtt`
export const reserve_att_guard = `${MEGALITH}/guardia/lockAppointment`
export const resume_att_guard = `${MEGALITH}/guardia/unlockAppointment`
export const start_att_specialist = `${MEGALITH}/specialist/startAtt`
export const start_att_onsite = `${MEGALITH}/onsite/startAtt`
export const start_chat_node = `${NODE_SERVER}/appointments/startChat`
export const start_chat_att = `${MEGALITH}/chatAtt/startChat`
export const create_async_mr = `${MEGALITH}/chatAtt/createMedicalRecord`
export const close_chat = `${NODE_SERVER}/appointments/closeChat`
export const close_att = `${MEGALITH}/appointments/closeAtt`
export const close_async_empty_att = `${MEGALITH}/chatAtt/closeEmptyChat`
export const update_async_medical_record = `${MEGALITH}/chatAtt/updateMedicalRecord`
export const generate_phone_code = `${MEGALITH}/contactValidationDoctor/phone/generateCode`
export const validate_phone_code = `${MEGALITH}/contactValidationDoctor/phone/validateCode`
export const close_async_att = `${MEGALITH}/chatAtt/closeChat`
export const motive_edit = `${MEGALITH}/medicalrecords/motive`
export const invitation_ws =`${NODE_SERVER}/invitation/doctor`
export const invitation_pharmacovigilance =`${NODE_SERVER}/invitation/pharmacovigilance`
export const invitations_array_ws =`${NODE_SERVER}/invitation/doctor/batch`
export const padrones = `${NODE_SERVER}/padrones/active`
export const mobility_batch_hisopados = `${NODE_SERVER}/delivery/mobility_batch_hisopados`
export const delivery_cmo = `${NODE_SERVER}/delivery/cmo`
export const get_services_params = `${NODE_SERVER}/delivery/params`
export const post_read_announcements = `${MEGALITH}/notifications/inbox/toggle/`
export const sisa_providers = `${MEGALITH}/provider/validation/sisa`
export const send_support_email = `${NODE_SERVER}/email/notifyOnProviderRegistration`
export const inform_transaction = `${MEGALITH}/guardia/informTransaction`
export const internals_auth = `${uma_apis_url}/internal/auth/userCustomToken`
export const get_appointments_count = `${MEGALITH}/providers/appointmentsCount`
export const post_provider_feedback =  `${MEGALITH}/providers/feedback`
export const acceptance_terms_camera = `${MEGALITH}/providers/termsAndConditions`
export const close_quickPrescription = `${MEGALITH}/quickPrescription/closeAttention`
export const provider_rating_status = `${MEGALITH}/providers/feedback/status`
export const provider_rating_change_status = `${MEGALITH}/providers/feedback/setInactive`
export const chattAtt_attach_file = `${MEGALITH}/chatAtt/saveAttached`
export const providerByUid = `${MEGALITH}/provider/getByUid`

/* FERIADOS */
export const feriados_api = "https://nolaborables.com.ar/api/v2/feriados/";

/* GOOGLE */
export const dynamic_links = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.NEXT_PUBLIC_FIREBASE_API_KEY}`;
export const uma_url_shortener = `${uma_apis_url}/shortlink`;

/* NLP */
export const history_nlp = `${NLP}/antecedentes`;
export const diagnostic_url = `${NLP}/diagnostic`;
export const diagnostic_search = `${NLP}/diagnostic_search`;

/* VADEMECUM */
export const vademecum = `${NLP}/vademecum`;

/* MEDIKIT */
export const medikit_path = `${uma_apis_url}/medikit`;
export const studies_search = `${NLP}/study_search`;
export const medikit_search = `${NLP}/medikit`;

/* RESEARCH */
export const research_validate = `${NODE_SERVER}/research`;
export const research_invite = `${NODE_SERVER}/research/sendInvite`;

// SHIFTS
export const shifts = `${uma_apis_url}/shifts`;
export const shifts_holidays = `${shifts}/holidays`;
export const shifts_custom_holidays = `${shifts}/customHolidays`;
export const shifts_delete = `${shifts}/delete`;
export const shifts_out_of_range = `${shifts}/outOfRangeAppointments`;
export const shifts_specialist_hour_delay = `${shifts}/specialistHourDelay`;

// Express Appointment
export const express_appointment = `${NODE_SERVER}/expressappointment`;

// Patient REFUNDS
export const mp_payment_url_refunds = `${NODE_SERVER}/payments/mercadopago/refunds`;

// LICENSES
export const licenses = `${NODE_SERVER}/providers/license`;
export const updateLicenseDoc = `${NODE_SERVER}/providers/license/update`;
export const deleteLicenseDoc = `${NODE_SERVER}/providers/license/delete`;

// MATRICULA
export const update_matricula = `${MEGALITH}/providers/matricula`;

//ai
export const search_corporate = `${NLP}/corporate_searcher`;
export const medical_qa = `${NLP}/medical_qa`;
export const ehr_summary = `${NLP}/ehr-summary`;
export const health_record_extractor = `${NLP}/health_record_extractor`;

//FARMATODO IA
export const prm_prh_report = `${NLP}/farmatodo_report`;
export const medicine_guide = `${NLP}/farmatodo_pautas`;

//transcriptions
export const create_trasncription = `${MEGALITH}/transcriptions/create`;

//FARMATODO
export const start_on_demand_pharmacovigilancy = `${MEGALITH}/onDemand/startOnDemand`;
export const close_on_demand_pharmacovigilancy = `${MEGALITH}/onDemand/closeOnDemand`;
export const start_on_site_pharmacovigilancy = `${MEGALITH}/pharmacovigilancy/startOnSite`;
export const close_on_site_pharmacovigilancy = `${MEGALITH}/pharmacovigilancy/closeOnSite`;
export const farmatodoStock =
  "https://uma-dot-multicountry-apigateway-dot-oracle-services-vzla.uc.r.appspot.com/algolia/products/objectID";

// Videocall Logs
export const provider_logs = `${MEGALITH}/provider/logs`;
export const check_patient_connection = `${MEGALITH}/patient/checkConnection`

// Expiration date
export const save_exipiration_date = `${MEGALITH}/expirationDates/save`;

// Provider data

export const get_medical_records_by_uid = `${MEGALITH}/medicalrecords/byUid/`

// Corporate Excluded Studies
export const get_excluded_studies = `${MEGALITH}/corporate/excludedStudies`
//POP UP NOTIFICATIONS
export const pop_up_prescription = `${MEGALITH}/prescriptions/validator`
